body {
  font-family: "Poppins" !important;
}

.login-card {
  max-width: 350px;
  margin-top: 27vh !important;
  margin-left: 3vh !important;
  text-align: left !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 20px !important;
  opacity: 1 !important;
  border: 1px solid #ebeded !important;
}
.topay-aspect-ratio {
  max-width: 400px !important;
}

.add-payment-btn {
  background: #20b883 !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  opacity: 1;
  width: 97% !important;
  font-weight: bold !important;
  height: 9vh !important;
  margin-left: 7px;
}

/* Print Customer and Order Details Modal */

.customerPrintDetails {
  padding: 10px;
  margin: 3px;
  font-family: "Times New Roman";
  font-weight: 600;
  border: 1px solid #000000;
  border-style: dashed;
}

.printPaymentBtn,
.cancelPaymentBtn {
  color: #ffffff;
  width: 30%;
  border-radius: 5px;
  background: #607d8b;
}

.cancelPaymentBtn:hover,
.printPaymentBtn:hover,
.cancelPaymentBtn:focus,
.printPaymentBtn:focus {
  border-radius: 5px;
  background: #607d8b;
  border-color: #607d8b;
  color: #ffffff;
}

.paymtName,
.paymtAmt {
  font-size: 16px;
}

.delete-icon {
  width: 10px !important;
  margin-top: -3px !important;
}

.paymt-card {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.text-align-center {
  text-align: center !important;
}

.topay-input {
  box-shadow: 0px 0.5px 4px #00000024 !important;
  border: 1px solid #ebeded !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  height: 9vh !important;
  margin-left: 7px;
  width: 96%;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
.para-margin {
  margin-bottom: 8px !important;
}

.amt-dial-btn {
  width: 88%;
  height: 10vh;
  border-radius: 20px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 30px;
  font-weight: 600;
}

.topay-entr-amt {
  padding-left: 7px;
  opacity: 1 !important;
  color: #030d1c;
  font-size: 16px;
  font-weight: 600;
}

.right-float {
  float: right !important;
  padding-right: 11px;
}

.wfifty {
  width: 50px !important;
}

.hfifty {
  height: 50px !important;
}

.no-border-card {
  border: none !important;
}

.selectedPaymentType {
  padding: 15px;
  border: 1px solid #ebeded !important;
  border-radius: 6px !important;
  background: #607d8b;
}

.selectedPaymentTypeName {
  font-size: 18px;
  color: #ffffff;
}

.paymt-name {
  font-size: 18px;
  color: #374957;
}

.rupees-tax {
  width: 12px !important;
  margin-top: -3px !important;
}

.prod-name {
  font-size: 14px !important;
  color: #030d1c !important;
  font-weight: normal !important;
}

.prod-name-details {
  text-align: left !important;
  font-size: 18px !important;
  letter-spacing: 0.24px !important;
  color: #030d1c !important;
  opacity: 1 !important;
  font-weight: bold !important;
}
.loginLogo {
  width: 60px;
  height: 50px;
  margin-top: -70px;
}
.copyright-main-div {
  position: absolute !important;
  bottom: 0 !important;
}
.login-label {
  text-align: left;
  letter-spacing: 0.4px;
  color: #000000;
  opacity: 1;
  font-size: 1.12em;
}
.login-input {
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  border: none !important;
}

.btnCommon {
  background-color: #0c4da9 !important;
  color: #ffffff !important;
  width: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 30px !important;
  opacity: 1;
}

.copyright {
  text-align: center;
  letter-spacing: 0px;
  color: #070000;
  font-size: 1em !important;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

/* pos page css starts*/
.menu-icon {
  width: 27px !important;
  margin-top: 5% !important;
  margin-left: 6px !important;
}

.pos-head-label {
  margin-left: 10% !important;
  margin-top: 10% !important;
  font-weight: Regular !important;
  font-size: 18px !important;
  letter-spacing: 0.36px !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.time-fmt {
  font-weight: Regular !important;
  font-size: 18px !important;
  color: #ffffff !important;
  margin-left: 2% !important;
  letter-spacing: 0.36px !important;
  opacity: 1 !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.time {
  color: #ffffff !important;
  /* margin-left:72% !important; */
  text-align: right;
  font-weight: Regular !important;
  font-size: 18px !important;
  letter-spacing: 0.36px;
  opacity: 1;
}

.pos-logo-icon {
  width: 27px !important;
  margin-top: 2.5% !important;
  margin-right: 6px !important;
}

.cardShadow {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.card-radius {
  border-radius: 5px !important;
}

.labelA {
  color: #0c4da9 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.labelB {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.downIcon {
  width: 26px !important;
  float: right !important;
}

.paid-icon {
  width: 8px !important;
  margin-top: -3px !important;
}

.amt-text-align {
  text-align: right;
}

.tax-dis-lbl {
  padding: 0 15px;
  text-align: left !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000000 !important;
  opacity: 1 !important;
}

.table-stl {
  height: 70.5% !important;
  overflow: auto !important;
  border: none !important;
}

.searchIcon {
  width: 18px !important;
}

.dialer-btn-image {
  height: 95% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.dialer-btn {
  height: 85%;
  width: 85%;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border-radius: 20px;
  border: 1px solid #ebeded;
  font-size: 30px;
  font-weight: 600;
}

.addBySKUbtn,
.addByUPCbtn {
  height: 85%;
  width: 85%;
  box-shadow: 0px 3px 6px #0000004c;
  background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 20px;
  font-size: 30px;
  font-weight: 600;
}

.productSpecChange {
  width: 10vh;
  height: 10vh;
  margin-bottom: 11px;
  box-shadow: 0px 3px 6px #0000004c;
  background: #607d8b;
  color: #ffffff;
  border: 1px solid #607d8b;
  border-radius: 15px;
}

button {
  outline: none;
}

.dialer-btn-image2 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.dialer-btn-image3 {
  height: 92% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.selected {
  border-left-style: solid !important;
  border-left-width: 5px !important;
  border-left-color: #000000 !important;
  /* opacity: 15% !important; */
  box-shadow: 0px 3px 10px #607d8b1a !important;
  border-radius: 5px !important;
  color: #374957;
}
.txt-cen {
  text-align: center !important;
}
.qty-card {
  height: 11.4vh !important;
  width: 100% !important;
}
.qty-img {
  width: 40px !important;
}

.quantityUpdateInput {
  padding: 12px;
  width: 95%;
  margin-left: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.quantity-btn,
.quantity-btn-zero,
.quantity-btn-ok {
  width: 88%;
  height: 10vh;
  border-radius: 20px;
  background: #ebeded;
  box-shadow: 0px 3px 6px #0000004c;
  border: 1px solid #ebeded;
  font-size: 30px;
  font-weight: 600;
}

.quantity-btn-zero {
  width: 94%;
  margin-left: 10px;
}

.quantity-btn-ok {
  height: 100%;
  background: #20b883;
  border: 1px solid #20b883;
}

.qty-lbl {
  font-size: 16px !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  letter-spacing: 0.32px !important;
  line-height: 36px !important;
  color: #374957 !important;
  opacity: 1 !important;
}
.no-border-crd {
  border: none !important;
}
.padding-ten {
  padding: 10 !important;
}
.aspect-ratio-for-update-qty {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}
.aspect-ratio-for-firstleft-icons {
  max-width: 400px !important;
  height: auto !important;
  min-height: 40px !important;
}
/*dashboard css starts*/
.workType {
  padding: 24px;
}
.imgWorkType {
  height: 37px;
  width: 33px;
}

.cardWorkType {
  text-align: center !important;
  box-shadow: 1px 2px 7px -3px !important;
}

.logoIcon {
  width: 96px !important;
  height: 77px !important;
}
.sync {
  width: 40px !important;
  height: 40px !important;
}

.logout {
  width: 40px !important;
  height: 40px !important;
}

.dbbackup {
  width: 40px !important;
  height: 40px !important;
}
.dashboard-label {
  text-align: left !important;
  /* font-family: Regular,Calibri; */
  font-size: 24px !;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
}
.dashboard-first-row {
  margin: 3vh 0vh 0vh 3vh !important;
}

.dashboard-info {
  text-align: left !important;
  font-size: 1.25em !important;
  letter-spacing: 0px !important;
  line-height: 39px !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.dashboard-greet {
  text-align: right !important;
  font-size: 1.25em !important;
  letter-spacing: 0px !important;
  line-height: 39px !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.col-text-align {
  text-align: center !important;
}
.dashboard-sync-logout-mainrow {
  margin: 15vh 0vh 0vh 3vh !important;
  float: right;
  padding-left: 1.7em;
  padding-right: 1.7em;
}

/*dashboard css ends */

/* sync page */
.rowGap {
  margin-top: 10px;
}

.logoIcon {
  width: 40px;
  height: 32px;
}
.mainDivStyl {
  padding: 10px;
}
.iconStyl {
  font-size: 30px;
  color: green !important;
}
/*ends*/

/* Customer Selection Radio Button */
.radio-lbl-cus,
.radio-lbl-ph {
  font-size: 16px;
  font-weight: bold;
}

.radio-lbl-ph {
  padding-left: 22px;
}

.customer-search-input {
  /* padding: 10px; */
  height: 7vh;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.customer-search-input-suffix {
  padding: 20px;
  margin-right: -12px;
  border-radius: 5px;
  border-left: 1px solid #cfd9e0;
}

.customer-search-input-suffix:hover {
  border: 1px solid #1890ff;
}

.customerSearchList {
  max-height: 60vh;
  min-height: 55vh;
}

.customerDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
}

.customerName {
  margin-bottom: 0;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.customerStatus {
  margin-bottom: 0;
  color: #0c4da9;
  font-size: 12px;
  font-weight: 600;
}

.noResultsText {
  font-size: 16px;
  color: #b9b9b9;
}

.addNewCustomer {
  font-size: 16px;
  color: #0c4da9;
}

.cutomerDetailsField {
  font-size: 16px !important;
  color: #000000;
}

.customerDetailsSec {
  padding-left: 30px;
}

.cutomerDetailsInput {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ebeded;
}

.cutomerDobInput {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ebeded;
}

.customerDetailsBtns {
  justify-content: center;
  padding-top: 20px;
}

.customerDetailsCancelBtn,
.customerDetailsSubmitBtnBtn {
  color: #ffffff;
  width: 20%;
  border-radius: 5px;
  background: #607d8b;
}

/* .customerDetailsCancelBtn:hover,.customerDetailsSubmitBtnBtn:hover, .customerDetailsCancelBtn:focus, .customerDetailsSubmitBtnBtn:focus {
          border-radius: 5px;  
          background: #607D8B;
          border-color: #607D8B;
          color: #ffffff;
        } */

.customerDetailsCancelBtn:hover,
.customerDetailsCancelBtn:focus {
  background: #707070;
  border-color: #607d8b;
  border-radius: 5px;
  color: #ffffff;
}

.customerDetailsSubmitBtnBtn:hover,
.customerDetailsSubmitBtnBtn:focus {
  background: #0c4da9;
  border-color: #0c4da9;
  border-radius: 5px;
  color: #ffffff;
}

.customerDetailsCancelBtn {
  background: #707070;
}

.customerDetailsSubmitBtnBtn {
  margin-left: 30px;
  background: #0c4da9;
}
/* Ends */

/* Returns Modal CSS */
.returnsButton {
  width: 15%;
  height: 6vh;
  font-size: 18px;
  margin-top: 50px;
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  float: right;
}

.returnsButton:hover {
  color: #ffffff !important;
  border-radius: 5px;
  background: #607d8b !important;
  border-color: #607d8b;
}

.returnProductsTable {
  width: 100%;
  border: 1px solid #ebeded;
  border-collapse: collapse;
  border-radius: 5px 5px 0px 0px !important;
}

.returnProductsTableThead {
  background-color: #ebeded;
}

.returnProductsTableTd,
.returnProductsTableTh {
  text-align: center;
  padding: 15px;
}

.returnProductsTableTd,
.returnProductsTableTdName {
  font-size: 12px;
  font-weight: 600;
}

.returnProductsTableTdName,
.returnProductsTableThName {
  text-align: left;
}

tr:hover {
  background-color: #ebeded;
}

.returnsQtyBtns {
  color: #607d8b;
}

.returnQunatity {
  display: flex;
  justify-content: center;
}

.returnsQtyInc,
.returnsQtyDec {
  color: #607d8b;
  padding-top: 8px;
  font-size: 15px;
}

.returnsQtyInc {
  padding-left: 10px;
}

.returnsQtyDec {
  padding-right: 10px;
}

.returnsQtyInput {
  border-radius: 5px;
  border: 1px solid #ebeded;
  text-align: center;
}

.returnsQtyFormitem {
  width: 15%;
  margin-bottom: 0;
}

/* Ends */

/*pos page css ends*/

/* pos new dialer pad*/
.wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 2fr);
  grid-gap: 2px;
  padding: 15px 8px 8px;
  height: 61vh;
}

.item2 {
  grid-column: 2/3;
  grid-row: 1/2;
  text-align: center;
}
.item3 {
  grid-column: 3/4;
  grid-row: 1/2;
  text-align: center;
}
.item4 {
  grid-column: 4/5;
  grid-row: 1/2;
  text-align: center;
}

.item5 {
  grid-column: 5/6;
  grid-row: 1/2;
  text-align: center;
}
/*second row*/
.item6 {
  grid-column: 2/3;
  grid-row: 2/2;
  text-align: center;
}
.item7 {
  grid-column: 3/4;
  grid-row: 2/2;
  text-align: center;
}
.item8 {
  grid-column: 4/5;
  grid-row: 2/2;
  text-align: center;
}
.item9 {
  grid-column: 5/6;
  grid-row: 2/2;
  text-align: center;
}
/*second row ends*/

/*third row*/
.item10 {
  grid-column: 2/3;
  grid-row: 3/3;
  text-align: center;
}

.item11 {
  grid-column: 3/4;
  grid-row: 3/3;
  text-align: center;
}
.item12 {
  grid-column: 4/5;
  grid-row: 3/3;
  text-align: center;
}
.item13 {
  grid-column: 5/6;
  grid-row: 3/3;
  text-align: center;
}
/* third row ends*/

/*fourth row starts*/
.item14 {
  grid-column: 2/3;
  grid-row: 4/4;
  text-align: center;
}
.item15 {
  grid-column: 3/4;
  grid-row: 4/4;
  text-align: center;
}
.item16 {
  grid-column: 4/5;
  grid-row: 4/4;
  text-align: center;
}
.item17 {
  grid-column: 5/6;
  grid-row: 4/4;
  text-align: center;
}

.item18 {
  grid-column: 2/3;
  grid-row: 5/5;
  text-align: center;
  bottom: 0;
}

/*fourth row ends*/

/* pos new dialer pad ends*/

.add-product-save,
.add-product-pay {
  width: 98% !important;
  height: 6vh;
  background: #607d8b !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  border-radius: 7px;
}

.add-product-pay {
  float: right !important;
  background: #ff8c00 !important;
}

.addProductInput {
  padding: 20px;
  width: 95%;
  margin-left: 13px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}

/* START - custom CSS While using functional components */
.sectionStyle {
  width: 100% !important;
  height: 100% !important;
  background-image: url("../assets/images/backgroundImage.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.spinLoader {
  font-size: 24;
}

.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

/* END - custom CSS  While using functional components */

/* Order History Modal CSS*/
.backArrowOrderHistory {
  font-size: 24px;
}

.orderHistoryModalTitle {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #0c4da9;
}

.parkedBillsHistoryModalTitle {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #0c4da9;
}

.orderHistorySearchInput {
  height: 7vh;
  width: 95%;
  margin-left: 20px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.orderHistorySearchInputSuffix {
  padding: 20px;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  margin-right: -12px;
  border-radius: 5px;
  /* border-left: 1px solid #CFD9E0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-left: 1px solid #cfd9e0;
}

.orderHistorySearchInputSuffix:hover {
  border: 1px solid #1890ff;
}

.orderHistoryAttribute {
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
}

.attributes {
  margin-bottom: 0;
  font-size: 16px;
  color: #a6adb4;
}

.attributesDropdown {
  float: right;
  font-size: 24px;
}

.orderHistoryCard,
.orderHistoryTabs,
.oderHistoryDetailsCard {
  width: 100%;
}

.orderHistoryCard {
  box-shadow: 0px 3px 6px #00000029 !important;
  min-height: 53vh;
}

.oderHistoryDetailsCard {
  background: #0c4da90d;
  margin-bottom: 10px;
}

.orderDate {
  color: #7d7d7d;
  font-size: 12px;
}

.orderCustomerName {
  float: right;
  color: #7d7d7d;
  font-size: 12px;
}

.dropdownCircleIcon {
  float: right;
}

.orderNum {
  margin-bottom: 0;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.orderStatus {
  margin-bottom: 0;
  float: right;
  color: #0c4da9;
  font-size: 12px;
  font-weight: 600;
}

.orderDetailsTable {
  width: 100%;
  margin-top: 10px;
}

.orderDetailsTableHeader {
  font-size: 16px;
  color: #607d8b;
}

.orderDetailsTableTh {
  text-align: center;
}

.orderDetailsData {
  text-align: center;
  font-size: 15px;
  color: #000000;
}

.orderDetailsDesc {
  text-align: left;
}

.orderDetailsTaxes,
.orderDetailsTotal {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}

.orderDetailsButtons {
  width: 100%;
}

.rePrintBtn,
.returnBtn,
.rePrintBtn:hover,
.returnBtn:hover {
  width: 50%;
  background: #607d8b;
  color: white;
  border-radius: 5px;
}

.returnBtn {
  float: right;
}

.parkedModalContent {
  font-size: 20px;
  font-weight: normal;
  color: #0c4da9;
  margin-bottom: 0;
  padding-top: 3px;
}

.parkedModalIcon {
  float: right;
  padding-left: 30px;
  padding-right: 20px;
  color: #20b883;
  font-size: 25px;
}

/* Ends */

/* Product Category Filters CSS */

.productsSearchCol {
  padding-right: 8px;
}

.categoryFiltersCol {
  border: 1px solid #ebeded;
  border-right: none;
  border-bottom: none;
  margin-top: -11px;
}

.productsFilterBtn {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #cfd9e0;
}

.productsFilterBtn:hover,
.productsFilterBtn:focus {
  border: 1px solid #cfd9e0;
}

.productSearchSuffix {
  font-size: 35px;
  color: #bac8d3;
}

/* Ends */

/* Cash/Carry Modal CSS */
.cashCarryBtn {
  width: 70%;
  float: right;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.deliveryBtn {
  width: 70%;
  margin-left: 20px;
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
}

.cashCarryBtn:hover,
.deliveryBtn:hover,
.cashCarryBtn:focus,
.deliveryBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

/* Ends */

/* Print Bill Popup css */
.printBillText {
  text-align: center;
  color: #0c4da9;
  font-size: 18px;
}

.yesBtn,
.noBtn {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  width: 50%;
}

.yesBtn:hover,
.noBtn:hover,
.yesBtn:focus,
.noBtn:focus {
  background-color: #ebeded;
  border-color: #ebeded;
  border-radius: 3px;
  color: #000000;
}

.yesBtn {
  float: right;
}

.noBtn {
  margin-left: 20px;
}
/* Ends */

/* @media print
  {
    .body {
      zoom: 100%;
      size: 80mm;
    }
    .non-printable { 
      display: none; 
    }
    .printable { 
      display: block; 
    }
    .customerPrintDetails{
      border: none !important;
    }

    @page {
      size: 80mm;
      size: A3;
    }
  } */

/* Ends */

@media print {
  body * {
    margin: 0;
    padding-left: 5px;
    visibility: hidden !important;
    zoom: 100% !important;
    box-shadow: none !important;
  }

  .section-not-to-print,
  .section-not-to-print * {
    display: none !important;
  }

  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
    width: 100% !important;
  }

  .section-to-print {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
  }
}

/* lfjd */
.main-container {
  background-color: #f8fbff;
  height: 100vh;
  padding: 10px;
}

.btn-card {
  height: 89vh;
  margin-top: 10px !important;
}

.btn-till {
  background-color: #314eaf !important;
  color: #ffffff !important;
  width: 60%;
  margin-top: 2%;
  font-weight: bold;
  font-size: large;
}
.btn-container {
  padding: 15%;
  text-align: center;
}
.card-shadow {
  box-shadow: 1px 2px 4px -3px !important;
  border-radius: 5px !important;
}
.logo-card {
  height: 7vh;
  padding: 4px !important;
}

.logo-icon {
  width: 40px;
  height: 32px;
}

.main-container {
  background-color: #f8fbff;
  height: 100vh;
  padding: 10px;
}

.main-card-container {
  margin-top: 10px !important;
  background-color: #f8fbff !important;
}

.main-card-first-open-till {
  height: 89vh;
}

.main-card-first {
  height: 86.4vh;
}

.card-notes {
  margin-top: 10px !important;
}

.main-card-second-open-till {
  height: 81.2vh;
  margin-top: 1px !important;
}

.btn-container {
  padding: 15%;
  text-align: center;
}
.card-shadow {
  box-shadow: 1px 2px 4px -3px !important;
  border-radius: 5px !important;
}
.logo-card {
  height: 7vh;
  padding: 4px !important;
}

.logo-icon {
  width: 40px !important;
  height: 32px !important;
}
.currency {
  background-color: #dfeafd !important;
  width: 100px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
}

.currency-count {
  font-weight: bold !important;
  text-align: center !important;
  width: 40% !important;
  font-size: 18px !important;
}

.total-input {
  width: 100 px !important;
  font-weight: 700px !important;
  text-align: right !important;
  border: none !important;
}
.col-align {
  text-align: right !important;
}

.col-amonut {
  text-align: right !important;
}

.formItem {
  margin-bottom: 10px !important;
  line-height: 10px !important;
}

.btn-confirm {
  background-color: #314eaf !important;
  color: #ffffff !important;
  width: 49.5%;
  margin-left: 1%;
  font-weight: bold;
  font-size: large;
}

.btn-cancel {
  background-color: #ffffff !important;
  color: #000000 !important;
  width: 49.5%;
  font-weight: bold;
  font-size: large;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* fdfdfdf*/

/* Product Info Modal */

.prod-head {
  font-size: 18px;
  font-weight: 600;
}

.prod-descp,
.prod-descp-price,
.prod-weight,
.prod-pack,
.prod-offer,
.prod-descp-price-mrp {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
}

.prod-descp-price-mrp {
  text-decoration: line-through;
}

.prod-descp-price {
  color: #535665;
}

.prod-pack {
  color: #707070;
}

.prod-offer {
  color: #ff0000;
}

.prod-qty {
  display: flex;
}

.prod-qty-dec,
.prod-qty-inc {
  padding: 8px;
  font-size: 18px;
  color: #607d8b;
  border: 0.5px solid #0000004c;
}

.prod-qty-dec {
  border-right: none;
}

.prod-qty-inc {
  border-left: none;
}

.prod-qty-input {
  border-radius: 0px;
  padding: 8px;
  border: 1px solid #0000004c;
  text-align: center;
}

.prod-qty-form {
  width: 20%;
  margin-bottom: 0;
}

.prod-qty-btn,
.prod-qty-btn:hover,
.prod-qty-btn:focus {
  width: 70%;
  background-color: #0c4da9;
  color: #ffffff;
  border: 1px solid #0c4da9;
  border-radius: 0px;
  float: right;
}

.prod-select {
  padding-top: 20px;
  padding-right: 15px;
  font-size: 24px;
  float: right;
}

.prod-selected-col {
  border: 1px solid #607d8b;
  background: #607d8b;
  border-radius: 0px 2px 2px 0px;
  color: #ffffff;
}

.prod-col {
  padding: 5px 0 5px 10px;
}

.prod-select-div {
  margin-bottom: 10px;
  background: #eef5ff;
  border: 1px solid #d3e5ff;
  border-radius: 5px;
}

.prod-div {
  margin-bottom: 10px;
  border: 1px solid #0000004c;
  border-radius: 5px;
}

/* Product Info Modal Ends*/
